import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import "@/assets/styles/g.css";
import "element-plus/dist/index.css";
import "@/assets/font/iconfont.css";
import WsRequest from "@/api/WsRequest";

const ws = new WsRequest();
const app = createApp(App);

app.config.globalProperties.$ws = ws;
app.config.globalProperties.getfileUrl = 'http://192.168.88.88:8088';
// app.config.globalProperties.getfileUrl = 'http://chat.123ai.pro:8088';
app.config.globalProperties.getimgUrl = 'https://img.123ai.pro/';
if (window.location.origin.indexOf("chat.123ai.pro") > -1) {
  app.config.globalProperties.getfileUrl = 'https://chat.123ai.pro/api/'  
}else if(window.location.origin.indexOf("http://114.242.3.137:8888") > -1){
  app.config.globalProperties.getfileUrl = 'http://114.242.3.137:8888/api'
}

if (process.env.VUE_APP_MODE === "web") {
  app.config.globalProperties.$winControl = require("./hooks/webControl");
} else {
  app.config.globalProperties.$winControl = require("./hooks/windowControl");
}
app.use(store).use(router).use(ElementPlus).mount("#app");
